import { createSelector } from 'reselect'

import { routes as routesServices, pricing, user, customer, selections, auth } from '../../services'

import {
  goToPage,
  backToPreviousPage,
  showModal,
  switchViewModeAction,
  goToEditReservation,
  goToRefund,
} from '../../actions'

import { getTrips } from '../Booking/provider.js'
import {
  allUserReservationsSelector,
  getCompaniesSelector,
  getManagersCompaniesSelector,
  getManagersSelectedCompany,
  getSelectedCompanyUsers,
  getPayers,
} from '../../services/user/selectors'
import { getCommonUser } from '../../services/user-selections'

import { fetchAllSailPackages } from '../../services/routes/sailPackages/actions'

import { startEditReservation } from '../../services/reservation/actions/reservation'
import {
  getCompanyMemberReservations,
  getCompanyMemberReservationsLength,
  getFilteredCompanyMemberReservations,
  getCompanyMemberReservationsLoading,
} from '../CompanyMembers/CompanyMembers/companyMembersSelectors'

const { sailPackages } = routesServices
const { getPrices } = pricing.selectors
const { getAllSailPackages, getSailPackagesDepartures } = sailPackages.selectors

const { getLocale } = selections
const { isLoggedInSelector, getJoinToken } = auth.selectors

const { changeUserFilters, fetchAllUserReservations, selectUsersCompany, inviteUserToCompany } = user.actions

const { fetchUserCustomerInfo } = customer.actions

const { customerSelector } = customer.selectors

const {
  getUserFiltersByType,
  filteredUserReservationsSelector,
  userDetailsSelector,
  getTicketsFiltersEnabled,
  allUserReservationsLengthSelector,
  sordedReservations,
} = user.selectors

const tripsFromReservation = (state) => (reservation) =>
  createSelector([() => reservation, getPrices, getAllSailPackages], getTrips)(state)

const actions = {
  showModal,
  goToPage,
  goToRefund,
  backToPreviousPage,
  fetchAllUserReservations,
  startEditReservation,

  changeUserFilters,
  switchViewModeAction,
  goToEditReservation,

  fetchUserCustomerInfo,
  selectUsersCompany,
  inviteUserToCompany,
  fetchAllSailPackages,
}

const selectors = {
  allUserReservationsSelector,
  allUserReservationsLengthSelector,
  customerSelector,
  getLocale,
  getJoinToken,
  isLoggedInSelector,
  filteredUserReservationsSelector,
  sordedReservations,
  getUserFiltersByType,
  tripsFromReservation,
  getCompaniesSelector,
  getManagersCompaniesSelector,
  getManagersSelectedCompany,
  getSelectedCompanyUsers,
  getCommonUser,
  userDetailsSelector,
  getTicketsFiltersEnabled,
  getSailPackagesDepartures,
  getPayers,
  getCompanyMemberReservations,
  getCompanyMemberReservationsLength,
  getFilteredCompanyMemberReservations,
  getCompanyMemberReservationsLoading,
}

export { actions, selectors }
