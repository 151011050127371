import React from 'react'
import PropTypes from 'prop-types'
import { UILayout } from 'bora-material-ui'
import { PageNotFound, PageTitle } from './'
import messages from '../../../../src/consts/messages'

const NotFoundContent = (props, { intl }) => (
  <UILayout column>
    <PageTitle title={intl.formatMessage(messages.staticPageNotFoundTitle)} />
    <UILayout style={{ color: '#ffffff' }}>
      <PageNotFound />
    </UILayout>
  </UILayout>
)

NotFoundContent.contextTypes = {
  intl: PropTypes.object,
}

export default NotFoundContent
