import React, { PureComponent } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { UIIcon } from 'bora-material-ui'

import { Locked } from '../../../components'
import { getStyle } from '../../../utils/liinilaevadThemeUtils'

const Wrapper = styled.div`
  color: ${getStyle('#0000A0', '#2c4684')};
  font-family: ${getStyle('SourceSansPro, Sans-Serif', 'GinesoNormDem, Fjalla One, sans-serif')};
  font-size: ${getStyle('20px', '23px')};
  font-weight: ${getStyle('700')};
  text-transform: ${getStyle('none', 'uppercase')};
`

const Route = styled.div`
  align-items: center;
  display: inline-flex;
`

const iconChevronStyle = {
  margin: '0 10px',
  fill: getStyle('#0000A0'),
}

class SailReferenceView extends PureComponent {
  render() {
    const { locked = true } = this.props

    const [fromLocation, toLocation] = this.getSailTitle()
    return (
      <Wrapper data-testid="sail-ref-view">
        <Locked locked={locked}>
          <Route>
            {fromLocation}
            <UIIcon type="iconChevron" {...iconChevronStyle} />
            {toLocation}
          </Route>
        </Locked>

        <div style={{ textTransform: getStyle('uppercase', 'none') }}>{this.getDepartureAt()}</div>
      </Wrapper>
    )
  }

  getSailRef() {
    const { sailPackage } = this.props

    if (sailPackage.sailRefs.length === 0) {
      throw new Error('Sail package has no sail references')
    }

    return sailPackage.sailRefs[0]
  }

  getSailTitle() {
    const { sailPackage } = this.props
    if (!sailPackage.title) return []
    return sailPackage.title.split('-')
  }

  getDepartureAt() {
    const { departureAt } = this.getSailRef()

    const dateTimeFormat = 'MMMM D, YYYY HH:mm'
    return moment(departureAt).format(dateTimeFormat)
  }

  // getDepartureFrom() {
  //   return this.getSailRef().departureFrom
  // }
  //
  // getArrivalTo() {
  //   return this.getSailRef().arrivalTo
  // }
}

export { SailReferenceView }
