import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { UILayout, UIText, UIImage } from 'bora-material-ui'
import { olderBrowser } from '../../../utils/browserUtils'

const cmsBase = window.brandProps.CMS_API_BASE

const styles = {
  linkImage: { backgroundSize: 'cover', height: '265px', maxWidth: '100%', ...(olderBrowser && { width: '450px' }) },
  link: { textDecoration: 'none', textAlign: 'center', display: 'flex', flexDirection: 'column' },
}

const xsStyles = {
  itemWrapper: { 'flex-start': true, style: { maxWidth: '100%', padding: 0 }, margin: 0 },
}

const ShipsListItem = (props) => {
  const { name, imageURL, shipURL, style } = props
  const imgSrc = `${cmsBase}${imageURL}`

  return (
    <UILayout
      column
      center
      j-flex-start
      margin="0 0 18px 0"
      style={{ maxWidth: '50%', ...style }}
      xs={xsStyles.itemWrapper}
    >
      <Link to={shipURL} style={styles.link}>
        <UIImage src={imgSrc} style={styles.linkImage} />
        <UIText color="#000000" size="24px" text={name} font="Roboto" margin="18px 0" />
      </Link>
    </UILayout>
  )
}

ShipsListItem.propTypes = {
  name: PropTypes.string.isRequired,
  imageURL: PropTypes.string.isRequired,
  shipURL: PropTypes.string.isRequired,
  style: PropTypes.object,
}

ShipsListItem.defaultProps = {
  style: {},
}

export default ShipsListItem
