import { createSelector } from 'reselect'
import { sortAllItems } from '../../../utils/ramda-extend'
import { compose, prop, last } from 'ramda'

const getTicket = prop('editTicket')
const errorsProp = prop('errors')
const lastError = compose(last, errorsProp)

function getError(state) {
  return state.editTicket.error
}

function getReservation(state) {
  return state.editTicket.reservation
}

function getReservationCurrency(state) {
  const { reservation } = state.editTicket

  if (!reservation) {
    return undefined
  }

  return reservation.paymentInfo.totalPrice.currency
}

function getReservationId(state) {
  return state.editTicket.reservationId
}

function getReservationToken(state) {
  return state.editTicket.token
}

function getSailPackageItems(state, sailPackageSeqN) {
  const { reservation } = state.editTicket

  if (!reservation) {
    return []
  }

  const { items = [] } = reservation
  const sailPackageItems = items.filter((item) => item.sailPackageSeqN === sailPackageSeqN)

  return sortAllItems(sailPackageItems)
}

function getSailPackages(state) {
  const reservation = getReservation(state)
  if (!reservation) {
    return []
  }

  return reservation.sailPackages.map((sailPackage) => ({
    ...sailPackage,
    items: getSailPackageItems(state, sailPackage.seqN),
  }))
}

export const selectAllEditTicketErrors = createSelector(getTicket, errorsProp)
export const selectLastEditTicketError = createSelector(getTicket, lastError)

export { getReservationCurrency, getError, getReservation, getReservationId, getSailPackages, getReservationToken }
