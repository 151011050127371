import React from 'react'
import { UILayout, UIText } from 'bora-material-ui'


const GavdosFooter = () => (
  <UILayout
    padding="40px 0"
  >
    <UIText
      align="left"
      color="#ffff"
      margin="0 0 0 20px"
      width="100%"
    >
      &copy; Island Eco Ferries Ltd {new Date().toLocaleDateString('et', { year: 'numeric' } )
    }
    </UIText>
  </UILayout>
)

export default GavdosFooter
