import React, { PureComponent } from 'react'
import messages from '../../../consts/messages'
import { pathOr } from 'ramda'

import { Details, Locked } from '../../../components'

class CustomerDetailsView extends PureComponent {
  render() {
    return (
      <Details>
        <Details.Item label={messages.fullName}>{this.getFullName()}</Details.Item>

        <Details.Item label={messages.contactPhone}>{this.getPhone()}</Details.Item>

        <Details.Item label={messages.emailShort}>{this.getEmail()}</Details.Item>
      </Details>
    )
  }

  getFullName = () => pathOr('', ['reservation', 'reservationOwner', 'fullName'], this.props)

  getPhone = () => {
    const phones = pathOr([], ['reservation', 'reservationOwner', 'phones'], this.props)
    return phones
      .filter((phone) => phone.intlCode && phone.phoneNumber)
      .map((phone) => `+${phone.intlCode} ${phone.phoneNumber}`)
      .join(', ')
  }

  getEmail = () => {
    const {
      reservation: { reservationOwner },
    } = this.props

    if (!reservationOwner) {
      return undefined
    }

    return <Locked>{reservationOwner.email}</Locked>
  }
}

export { CustomerDetailsView }
