import { compose, pathOr, length } from 'ramda'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { withRouter } from 'react-router-dom'

import { withAlert } from '../../../components'
import { EditTicketView } from './EditTicketView'
import {
  fetchReservation,
  openCarModal,
  openCustomerModal,
  openSailItemsModal,
  openSailReferenceModal,
  openTrailerModal,
  openPassengerModal,
} from './editTicketSagas'
import {
  getError,
  getReservation,
  getReservationCurrency,
  getReservationId,
  getReservationToken,
  getSailPackages,
} from './editTicketSelectors'
import { injectIntl } from 'react-intl'
import { actions, selectors } from '../../Booking/provider'
import { vehiclesWeightEditAllowed } from '../../../services/pricing/reducers/selectors'
import { setModalData, showModal, setPassengerToEdit } from '../../../actions'
import { getAvailableReplacementLegs } from '../../../services/schedule/reducers'
import { removeSingleGuest } from './actions'
import { getBookingConditionsForRoute } from '../../../services/reservation/selectors'

function mapStateToProps(state) {
  return {
    currency: getReservationCurrency(state),
    error: getError(state),
    reservation: getReservation(state),
    reservationId: getReservationId(state),
    reservationToken: getReservationToken(state),
    sailPackages: getSailPackages(state),
    trips: selectors.tripsFromReservation(state)(getReservation(state)),
    customWeightMap: vehiclesWeightEditAllowed(state),
    editTicketSailPackage: pathOr([], ['editTicket', 'reservation', 'sailPackages'])(state),
    editMode: selectors.getEditReservationMode(state),
    replacementLegsAvailable: length(getAvailableReplacementLegs(state) || []) > 1,
    outOfBookingBoundary: getBookingConditionsForRoute(state),
    isFetching: state.fetching,
  }
}

const mapDispatchToProps = {
  fetchReservation,
  finishEditReservation: actions.finishEditReservation,
  openCarModal,
  openCustomerModal,
  openSailItemsModal,
  openSailReferenceModal,
  openTrailerModal,
  showModal,
  setModalData,
  setPassengerToEdit,
  openPassengerModal,
  removeSingleGuest,
}

function submitHandler(values, dispatch) {
  dispatch(() => {})
}

const EDIT_TICKET_FORM = 'editTicketForm'

const reduxFormConfig = {
  form: EDIT_TICKET_FORM,
  onSubmit: submitHandler,
}

const EditTicketContainer = compose(
  injectIntl,
  reduxForm(reduxFormConfig),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withAlert
)(EditTicketView)

export { EditTicketContainer }
