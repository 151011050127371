import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import { connect } from 'react-redux'
import { UILayout } from 'bora-material-ui'
import CircularProgress from '@material-ui/core/CircularProgress'
import { isEmpty } from 'ramda'
import { NotFoundContent, PageTitle } from '../Common'
import ShipsListItem from './ShipsListItem'
import { getAllShips } from '../../../services/sales-static/api'
import { mapStateToPropsForStaticPages, mediumImagesPath, smallImagesPath } from '../../../services/sales-static/utils'

const ShipsListContent = (props) => {
  const generateShipsListItems = (vessels) =>
    vessels.map((item, i) => (
      <ShipsListItem
        key={item.id}
        name={item.name}
        imageURL={mediumImagesPath(item) || smallImagesPath(item)}
        shipURL={`${props.match.url}/${item.slug}`}
        style={i % 2 ? { padding: '0 0 0 10px' } : { padding: '0 10px 0 0' }}
      />
    ))

  const { data: shipsData } = useSWR('static-fleet', () => getAllShips(props.locale))

  if (!shipsData) return <NotFoundContent />

  return isEmpty(shipsData) ? (
    <NotFoundContent />
  ) : (
    <UILayout column>
      <PageTitle title={shipsData.subject} />
      <UILayout wrap margin="38px 0 0 0" xs={{ wrap: false, column: true, margin: '16px 0 0 0' }}>
        {shipsData.vessels ? (
          generateShipsListItems(shipsData.vessels)
        ) : (
          <UILayout j-flex-center margin="60px">
            <CircularProgress />
          </UILayout>
        )}
      </UILayout>
    </UILayout>
  )
}

ShipsListContent.propTypes = {
  locale: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  pageTitle: PropTypes.string,
}

ShipsListContent.defaultProps = {
  pageTitle: '',
}

export default connect(mapStateToPropsForStaticPages)(ShipsListContent)
