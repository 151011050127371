import React from 'react'
import { UILayout } from 'bora-material-ui'


const bgImage = {
  backgroundRepeat: 'round'
}

const SunlinePromotions = () => (
  <UILayout
    position="absolute"
    height="310px"
    top="42%"
    bg="images/sunlines/events.jpg"
    style={bgImage}
    xs={0}
    xl={1}
  />
)

export default SunlinePromotions
