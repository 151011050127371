import { connect } from 'react-redux'
import { compose, isEmpty, not, all, F } from 'ramda'
import { injectIntl } from 'react-intl'
import { getFormValues } from 'redux-form'
import { modifyReservation } from '../EditTicket/editTicketSagas'
import { showModal } from '../../../actions'
import { CustomerModalView } from './CustomerModalView'
import { getReservation } from '../EditTicket/editTicketSelectors'
import { getCountries, userDetailsSelector } from '../../../services/user/selectors'
import {
  getCurrentReservationOwner,
  getEditReservationOwner,
  getFormModeBy,
  selectCustomerByOneOf,
  selectFormMode,
} from '../../Booking/forms/CustomerInfo/CustomerInfoContainer'
import { FORM_MODE_COMPANY } from '../../Booking/forms/CustomerInfo/consts'
import { selectors } from '../../Booking/provider'

const allNotNull = all((value) => {
  if (typeof value === 'number') {
    return value >= 0
  }
  return Boolean(value) // todo: maybe check for empty string only
})

const notEmpty = compose(not, isEmpty)

function mapStateToProps(state, { editMode = false }) {
  const countries = getCountries(state)
  const currentValues = getFormValues('contact')(state) || {}
  let disableNextStep = false
  const isLogged = selectors.getToken(state)

  const customer = selectCustomerByOneOf(state)([
    getCurrentReservationOwner,
    editMode ? getEditReservationOwner : F,
    isLogged ? selectors.customerSelector : F,
  ])

  const formMode = selectFormMode(state) || getFormModeBy(customer)

  if (formMode === FORM_MODE_COMPANY) {
    const {
      email,
      firstName,
      lastName,
      phoneCode,
      phoneNumber,
      country,
      city,
      legalName,
      registrationNumber,
      zip,
      addressLine,
    } = currentValues

    const allValuesOK = allNotNull([
      email,
      firstName,
      lastName,
      phoneCode,
      phoneNumber,
      country,
      city,
      legalName,
      registrationNumber,
      zip,
      addressLine,
    ])
    disableNextStep = !allValuesOK
  } else {
    const { email = '', firstName = '', lastName = '', phoneCode, phoneNumber } = currentValues
    const allValuesOK = allNotNull([email, firstName, lastName, phoneCode, phoneNumber])
    disableNextStep = !allValuesOK
  }

  const userDetails = userDetailsSelector(state)
  return {
    currentValues,
    reservation: getReservation(state),
    userLoggedIn: notEmpty(userDetails),
    countries,
    disableNextStep,
    formMode: selectFormMode(state),
  }
}

const mapDispatchToProps = {
  modifyReservation,
  showModal,
}

const CustomerModalContainer = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(CustomerModalView)

export { CustomerModalContainer }
