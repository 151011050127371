import React from 'react'
import Loadable from 'react-loadable'
import { UILayout } from 'bora-material-ui'

const xsStyles = {
  height850: { height: '850px' },
}

const LoadablePromotionsView = Loadable({
  loader: () => import('./containers/PromotionsView'),
  loading: () => <UILayout height="1123px" xs={xsStyles.height850} />,
})

export default LoadablePromotionsView
