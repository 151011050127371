import {
  watchChangeSailPackageByLeg,
  watchFetchReservation,
  watchOpenCarModal,
  watchOpenCustomerModal,
  watchOpenSailItemsModal,
  watchOpenPassengerModal,
  watchOpenSailReferenceModal,
  watchOpenTrailerModal,
  watchSetError,
  watchSetModalError,
  watchModalClosing,
  watchRemoveSingleGuest,
} from './EditTicket/editTicketSagas'

export function* ticketSaga() {
  yield [
    watchChangeSailPackageByLeg(),
    watchFetchReservation(),
    watchOpenCarModal(),
    watchOpenCustomerModal(),
    watchOpenSailItemsModal(),
    watchOpenPassengerModal(),
    watchOpenSailReferenceModal(),
    watchOpenTrailerModal(),
    watchSetError(),
    watchSetModalError(),
    watchModalClosing(),
    watchRemoveSingleGuest(),
  ]
}
