import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { Details } from '../../../components'
import messages from '../../../consts/messages'

const Value = styled.span`
  font-size: 18px;
`

const AccentValue = styled(Value)`
  font-weight: bold;
`

class PaymentInfoView extends PureComponent {
  render() {
    const {
      paymentInfo: {
        totalPrice: { amount, amountWithoutTaxes, taxAmount },
      },
    } = this.props

    return (
      <Details stretched>
        <Details.Item label={messages.subTotalWithTax}>
          <Value>{this.formatValue(amountWithoutTaxes)}</Value>
        </Details.Item>

        <Details.Item label={messages.tax}>
          <Value>{this.formatValue(taxAmount)}</Value>
        </Details.Item>

        <Details.Item label={messages.subTotal}>
          <AccentValue>{this.formatValue(amount)}</AccentValue>
        </Details.Item>
      </Details>
    )
  }

  formatValue(value) {
    const {
      paymentInfo: {
        totalPrice: { currency },
      },
      intl,
    } = this.props

    return intl.formatNumber(value, {
      currency,
      minimumFractionDigits: 2,
      style: 'currency',
    })
  }
}

export { PaymentInfoView }
