import { pathOr, propEq, filter, update, path, prop, identity } from 'ramda'
import { createAction } from 'redux-actions'
import { delay } from 'redux-saga'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { RESERVATION_MODIFIED } from '../../../services/reservation/consts/reservation'

import {
  reservationModificationModifyService,
  reservationModificationStartService,
  reservationEmptyModificationModifyService,
} from '../../../services/reservation/api'
import { showModal, setSelectedSailPackageIndex } from '../../../actions'
import { selections as selectionsService } from '../../../services'
import { getReservation } from './editTicketSelectors'

import { fetchAvailableReplacementLegs, setSelectedSailForEdit } from '../../../services/schedule/actions/schedule'

import { getEditReservation, getErrorDetails, getIsEditProcessStarted } from '../../../services/reservation/selectors'
import { clearReservationError, editDataInReservation } from '../../../services/reservation/actions/reservation'
import { setTrailerInputType, setVehicleInputType } from '../../../services/user-selections'
import { fetchInventoryForEditReservationAction } from '../../../services/inventory/actions'
import { removeSingleGuest } from './actions'
import { reset } from 'redux-form'

const changeSailPackageByLeg = createAction('EDIT_TICKET:CHANGE_SAIL_PACKAGE_BY_LEG')
const fetchReservation = createAction('EDIT_TICKET:FETCH_RESERVATION')
const modifyReservation = createAction('EDIT_TICKET:MODIFY_RESERVATION_MODIFY', identity)
const openCarModal = createAction('EDIT_TICKET:OPEN_CAR_MODAL')
const openCustomerModal = createAction('EDIT_TICKET:OPEN_CUSTOMER_MODAL')
const openSailItemsModal = createAction('EDIT_TICKET:OPEN_SAIL_ITEMS_MODAL')
const openPassengerModal = createAction('EDIT_TICKET:OPEN_PASSENGER_MODAL')
const openSailReferenceModal = createAction('EDIT_TICKET:OPEN_SAIL_REFERENCE_MODAL')
const openTrailerModal = createAction('EDIT_TICKET:OPEN_TRAILER_MODAL')
const removeError = createAction('EDIT_TICKET:REMOVE_ERROR')
const removeModalError = createAction('EDIT_TICKET:REMOVE_MODAL_ERROR')
const setError = createAction('EDIT_TICKET:SET_ERROR')
const setModalError = createAction('EDIT_TICKET:SET_MODAL_ERROR')
const addError = createAction('EDIT_TICKET:ADD_RES_EDIT_ERROR')
const updateReservation = createAction('EDIT_TICKET:UPDATE_RESERVATION', identity)

const updateLegacyCurrentReservation = createAction(RESERVATION_MODIFIED)

function* changeSailPackageByLegHandler({ payload: { leg, selectedSailPackageIndexToEdit } }) {
  const sailPackage = yield select(pathOr({}, ['sailPackages', leg.code]))
  if (!sailPackage.code) {
    return
  }

  const selectedSailPackages = yield select(selectionsService.getSelectedSailPackages)
  yield put(selectionsService.selectSailPackage({ sailPackage, isClear: false }))
  const changedSailPackages = update(
    selectedSailPackageIndexToEdit,
    {
      code: sailPackage.code,
      route: sailPackage.route.code,
      legSequence: filter(propEq('code', sailPackage.code), sailPackage.route.legs),
      source: 'saga',
    },
    selectedSailPackages
  )
  yield put(selectionsService.setSelectedPackages(changedSailPackages))
}

function* fetchReservationHandler(action) {
  const started = yield select(getIsEditProcessStarted)
  const userNeedToPayAfterEdit = yield select(prop('userNeedToPayAfterEdit'))
  const { payload: reservationToken } = action

  const shouldNotStartEdit = typeof userNeedToPayAfterEdit === 'number' && userNeedToPayAfterEdit > 0

  if (started || shouldNotStartEdit) {
    const reservationUnderEdit = yield select(getEditReservation)
    const { token: editResToken } = reservationUnderEdit

    if (editResToken === reservationToken) {
      try {
        const reservation = yield call(reservationEmptyModificationModifyService, reservationToken)
        yield put(updateReservation(reservation))
      } catch (error) {
        const { message = 'Unable to run the reservation edit' } = error
        yield put(setError(message))
      }
    }
    return
  }

  try {
    const reservation = yield call(reservationModificationStartService, reservationToken)
    yield put(updateReservation(reservation))
  } catch (error) {
    console.error(error.message)
  }
}

function selectModalError(code, message) {
  const modalErrors = {
    FAILED_TO_ALLOCATE_PAX_INVENTORY: 'failToAllocate',
    FAILED_TO_ALLOCATE_RESERVE_INVENTORY: 'failToAllocateResident',
    FAILED_TO_ALLOCATE_CAR_DECK_INVENTORY: 'customWeightFormError',
    FAILED_TO_ALLOCATE_CAR_DECK_INVENTORY_BICYCLE: 'failToAllocateBicycle',
  }

  // Server return one error code ('FAILED_TO_ALLOCATE_CAR_DECK_INVENTORY') for vehicles,
  // no difference 'HIGH METERS' or 'BICYCLE'
  const type = 'BICYCLE'
  const customCode = RegExp(type).test(message) ? `${code}_${type}` : code

  return modalErrors[customCode] || code
}

function* modifyReservationHandler(action) {
  const { reservationToken, reservationId, ...requestPayload } = action.payload

  try {
    const reservation = yield call(reservationModificationModifyService, reservationToken, requestPayload)
    yield put(updateReservation(reservation))
    yield put(showModal(''))
  } catch (error) {
    const { data: { code = '', message = '' } = {} } = error

    const modalError = selectModalError(code, message)
    if (modalError) {
      yield put(setModalError(modalError))
    } else {
      yield put(setError(error.message))
    }
  }
}

function* openCarModalHandler() {
  const reservation = yield select(getReservation)
  yield put(updateLegacyCurrentReservation(reservation))
  yield put(showModal('car'))
}

function* openCustomerModalHandler() {
  const reservation = yield select(getReservation)
  yield put(updateLegacyCurrentReservation(reservation))
  yield put(showModal('customerForm'))
}

function* openSailItemsModalHandler({ payload: sailPackageIndex }) {
  const reservation = yield select(getReservation)
  yield put(fetchInventoryForEditReservationAction({ reservation, sailPackageIndex }))
  yield put(updateLegacyCurrentReservation(reservation))
  yield put(setSelectedSailPackageIndex(sailPackageIndex))
  yield put(setTrailerInputType('simple'))
  yield put(setVehicleInputType('simple'))
  yield put(showModal('tickets'))
}

function* openPassengerModalHandler() {
  const reservation = yield select(getReservation)
  yield put(updateLegacyCurrentReservation(reservation))
  yield put(showModal('passenger'))
}

function* openSailReferenceModalHandler({ payload: sailPackageIndex }) {
  const reservation = yield select(getReservation)
  yield put(updateLegacyCurrentReservation(reservation))
  yield put(setSelectedSailPackageIndex(sailPackageIndex))
  const sailRefId = pathOr(0, ['sailPackages', [sailPackageIndex], 'sailRefs', [0], 'sailRefId'], reservation)
  const sailStatus = pathOr(0, ['sailPackages', [sailPackageIndex], 'sailRefs', [0], 'status'], reservation)
  if (sailStatus === 'CANCELLED') {
    yield put(fetchAvailableReplacementLegs(sailRefId))
  }
  yield put(showModal('datetime'))
}

function* openTrailerModalHandler() {
  const reservation = yield select(getReservation)
  yield put(updateLegacyCurrentReservation(reservation))
  yield put(showModal('trailer'))
}

function* setErrorHandler() {
  const TEN_SECONDS = 10000
  yield delay(TEN_SECONDS)
  yield put(removeError())
}

function* setModalErrorHandler() {
  const TEN_SECONDS = 10000
  yield delay(TEN_SECONDS)
  yield put(removeModalError())
}

function* handleRemoveSingleGuest({ payload = {} }) {
  const { guestSeqN, sailSeqNum = 0, reservationId } = payload
  yield put(
    editDataInReservation({
      reservationId,
      dataToEdit: {
        modifications: [
          {
            __type: 'deleteItems',
            ticketsRequests: [
              {
                sailPackageSeqN: sailSeqNum + 1,
                seqN: guestSeqN,
              },
            ],
          },
        ],
      },
    })
  )
}

const selectSailRefId = pathOr('', ['sailRefs', 0, 'sailRefId'])

function* onClosingModal({ payload }) {
  if (payload === '') {
    yield put(removeModalError())
    const { code = '' } = yield select(getErrorDetails)
    if (code && code === 'FAILED_TO_ALLOCATE_CAR_DECK_INVENTORY') {
      yield put(clearReservationError())
    }

    const getEditReservation = path(['editTicket', 'reservation'])
    const reservationOnEdit = yield select(getEditReservation)
    const editingReservation = Boolean(reservationOnEdit)

    if (editingReservation) {
      const { sailPackages = [] } = reservationOnEdit
      yield put(reset('localTravellerIDForm'))
      for (const sp of sailPackages) {
        // yield put(
        //   setSelectedSail({
        //     sailPackageCode: sp.code,
        //     selectedSailId: pathOr('', ['sailRefs', 0, 'sailRefId'])(sp),
        //   }),
        // )
        yield put(
          setSelectedSailForEdit({
            sailPackageCode: sp.code,
            selectedSailId: selectSailRefId(sp),
          })
        )
      }
    }
  }
}

function* watchChangeSailPackageByLeg() {
  yield takeEvery(changeSailPackageByLeg, changeSailPackageByLegHandler)
}

function* watchFetchReservation() {
  yield takeEvery(fetchReservation, fetchReservationHandler)
}

function* watchModifyReservation() {
  yield takeEvery(modifyReservation, modifyReservationHandler)
}

function* watchOpenCarModal() {
  yield takeEvery(openCarModal, openCarModalHandler)
}

function* watchOpenCustomerModal() {
  yield takeEvery(openCustomerModal, openCustomerModalHandler)
}

function* watchOpenSailItemsModal() {
  yield takeEvery(openSailItemsModal, openSailItemsModalHandler)
}

function* watchOpenPassengerModal() {
  yield takeEvery(openPassengerModal, openPassengerModalHandler)
}

function* watchOpenSailReferenceModal() {
  yield takeEvery(openSailReferenceModal, openSailReferenceModalHandler)
}

function* watchOpenTrailerModal() {
  yield takeEvery(openTrailerModal, openTrailerModalHandler)
}

function* watchSetError() {
  yield takeEvery(setError, setErrorHandler)
}

function* watchSetModalError() {
  yield takeEvery(setModalError, setModalErrorHandler)
}

function* watchModalClosing() {
  yield takeEvery(showModal, onClosingModal)
}

function* watchRemoveSingleGuest() {
  yield takeEvery(removeSingleGuest, handleRemoveSingleGuest)
}

export {
  changeSailPackageByLeg,
  fetchReservation,
  modifyReservation,
  openCarModal,
  openCustomerModal,
  openSailItemsModal,
  openPassengerModal,
  openSailReferenceModal,
  openTrailerModal,
  removeError,
  setError,
  setModalError,
  removeModalError,
  updateReservation,
  watchChangeSailPackageByLeg,
  watchFetchReservation,
  watchModifyReservation,
  watchOpenCarModal,
  watchOpenCustomerModal,
  watchOpenSailItemsModal,
  watchOpenPassengerModal,
  watchOpenSailReferenceModal,
  watchOpenTrailerModal,
  watchSetError,
  watchSetModalError,
  watchModalClosing,
  watchRemoveSingleGuest,
  addError,
}
