import { handleActions } from 'redux-actions'

import { removeError, setError, updateReservation, setModalError, removeModalError, addError } from './editTicketSagas'
import {
  EDIT_DATA_IN_RESERVATION_SUCCESS,
  EXTEND_RESERVATION_EXPIRE,
  SET_RESERVATION,
} from '../../../services/reservation/consts/reservation'

const initialState = {
  error: null,
  reservation: null,
  reservationId: null,
  token: '',
  modalError: null,
  errors: [],
}

const editTicketReducer = handleActions(
  {
    [removeError.toString()](state) {
      return {
        ...state,
        error: null,
      }
    },

    [addError.toString()](state, { payload }) {
      const { errors } = state
      return {
        ...state,
        errors: [...errors, payload],
      }
    },

    [removeModalError.toString()](state) {
      return {
        ...state,
        modalError: null,
      }
    },

    [setError.toString()](state, action) {
      const { payload: error } = action

      return {
        ...state,
        error,
      }
    },

    [setModalError.toString()](state, action) {
      const { payload: modalError } = action

      return {
        ...state,
        modalError,
      }
    },

    [updateReservation.toString()](state, action) {
      const { payload: reservation } = action

      return {
        ...state,
        reservation,
      }
    },

    [SET_RESERVATION](state, action) {
      const {
        payload: {
          reservation: { reservationId, token },
        },
      } = action

      return {
        ...state,
        errors: [],
        reservationId,
        token,
      }
    },

    [EXTEND_RESERVATION_EXPIRE](state, action) {
      const { payload } = action
      return {
        ...state,
        ...(state.reservation && {
          reservation: {
            ...state.reservation,
            expireAt: payload,
          },
        }),
      }
    },

    [EDIT_DATA_IN_RESERVATION_SUCCESS](state, action) {
      const { payload: reservation } = action

      return {
        ...state,
        reservation,
      }
    },
  },
  initialState
)

export { editTicketReducer }
