import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { UIButton, UIIcon, UILayout, UIText } from 'bora-material-ui'

import messages from '../../../consts/messages'
import { areLicensePlatesForRoundTripDifferent, mergeVehicleData } from '../../../services/reservation/utils'
import { showModal } from '../../../actions/index'
import { getStyle } from '../../../utils/liinilaevadThemeUtils'

const xsStyles = {
  topText: {
    size: '14px',
  },
  bottomText: {
    size: '18px',
  },
  buttonText: {
    width: 'auto',
  },
}

class FinishButtonView extends PureComponent {
  onFinish = () => {
    const { finishEditReservation, licencePlatesAreDifferent, showModal, reservation, differenceToPay } = this.props
    if (licencePlatesAreDifferent) {
      showModal('differentLicensePlateOnRoundTrip')
    } else {
      finishEditReservation({
        reservation,
        reservationToken: reservation.token,
        reservationId: reservation.reservationId,
        differenceToPay,
      })
    }
  }

  render() {
    const { differenceToPay, userNeedToPayAfterEdit, disabled = false } = this.props
    const { muiTheme } = window.brandProps || {}
    return (
      <UIButton
        disabled={disabled}
        display-if={!userNeedToPayAfterEdit}
        id="buttonStyle"
        click={this.onFinish}
        height="102px"
        xs={xsStyles.buttonText}
        {...(muiTheme.ids.buttonActionStyles && { ...muiTheme.ids.buttonActionStyles.nextButton })}
        borderRadius={getStyle(
          '12px',
          muiTheme.ids.buttonActionStyles &&
            muiTheme.ids.buttonActionStyles.nextButton &&
            muiTheme.ids.buttonActionStyles.nextButton.borderRadius
            ? muiTheme.ids.buttonActionStyles.nextButton.borderRadius
            : 'inherit'
        )}
      >
        <UILayout center j-flex-space-between margin="0 27px" lineHeight="30px" height="100%" width="auto">
          <UILayout
            column
            width="auto"
            style={{ ...(muiTheme.ids.buttonActionStyles && { ...muiTheme.ids.buttonActionStyles.subtitles }) }}
          >
            <UIText
              id="buttonLabelTextStyle"
              align="left"
              size="18px"
              opacity="0.5"
              translate={messages.clickTo}
              xs={xsStyles.topText}
              margin={
                muiTheme.ids.buttonActionStyles && muiTheme.ids.buttonActionStyles.subtitles
                  ? muiTheme.ids.buttonActionStyles.subtitles.margin
                  : '0'
              }
            />
            <UIText
              id="buttonTextStyle"
              align="left"
              size="24px"
              textTransform="uppercase"
              translate={differenceToPay > 0 ? messages.payTheDifference : messages.saveChanges}
              xs={xsStyles.bottomText}
              font={
                muiTheme.ids.buttonActionStyles && muiTheme.ids.buttonActionStyles.titles
                  ? muiTheme.ids.buttonActionStyles.titles.fontFamily
                  : 'inherit'
              }
              {...(muiTheme.ids.buttonActionStyles && { ...muiTheme.ids.buttonActionStyles.titles })}
            />
          </UILayout>
          <UIIcon fill="white" type="selectTicketArrow" />
        </UILayout>
      </UIButton>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const { userNeedToPayAfterEdit } = state

  const { reservation } = ownProps
  const isRoundTrip = reservation.sailPackages && reservation.sailPackages.length > 1
  const itemsOfVehicleSubType =
    reservation.items && reservation.items.filter((item) => item.priceCategorySubType === 'VEHICLE')
  const itemsOfTrailerSubType =
    reservation.items && reservation.items.filter((item) => item.priceCategorySubType === 'TRAILER')
  const { vehicles: vehiclesFromReservation } = reservation
  const mergedArrayOfVehicleSubType = mergeVehicleData(itemsOfVehicleSubType, vehiclesFromReservation)
  const mergedArrayOfTrailerSubType = mergeVehicleData(itemsOfTrailerSubType, vehiclesFromReservation)
  const licencePlatesAreDifferent = isRoundTrip
    ? areLicensePlatesForRoundTripDifferent(mergedArrayOfVehicleSubType, mergedArrayOfTrailerSubType)
    : false
  return {
    userNeedToPayAfterEdit,
    licencePlatesAreDifferent,
  }
}
const mapDispatchToProps = { showModal }
const FinishButtonContainer = connect(mapStateToProps, mapDispatchToProps)(FinishButtonView)

export { FinishButtonContainer as FinishButton }
