import React, { Fragment, PureComponent } from 'react'
import { UIBottomSlider } from 'bora-material-ui'

import { Panel } from '../../../components'
import messages from '../../../consts/messages'
import CustomerInfo from '../../Booking/forms/CustomerInfo'
import { pathOr } from 'ramda'
import { FORM_MODE_COMPANY, FORM_MODE_PRIVATE } from '../../Booking/forms/CustomerInfo/consts'

class CustomerModalView extends PureComponent {
  render() {
    const { intl, disableNextStep } = this.props

    return (
      <Fragment>
        <Panel>
          <CustomerInfo editMode />
        </Panel>

        <Panel>
          <UIBottomSlider
            isEditPopup={true}
            isPopup={true}
            isNextAvailable={!disableNextStep}
            nextAction={this.applyChanges}
            prevAction={() => this.props.showModal('')}
            prevTitle={intl.formatMessage(messages.cancel)}
            nextTitle={intl.formatMessage(messages.applyChanges)}
            editMode
          />
        </Panel>
      </Fragment>
    )
  }

  applyChanges = () => {
    const {
      currentValues: {
        firstName,
        lastName,
        phoneCode: intlCode,
        phoneNumber,
        country,
        city,
        legalName,
        registrationNumber,
        zip,
        addressLine,
      },
      modifyReservation,
      reservation,
      showModal,
      countries = [],
      formMode = FORM_MODE_PRIVATE,
    } = this.props

    const countryCode = pathOr('', [country, 'code'])
    const companyInfo = {
      country: countryCode(countries),
      city,
      legalName,
      registrationNumber,
      zip,
      address: addressLine,
    }

    const updateOwnerInfoModification = {
      __type: 'updateOwnerInfo',
      data: {
        firstName,
        lastName,
        phones: [{ intlCode, phoneNumber }],
        ...(formMode === FORM_MODE_PRIVATE && {
          companyInfo: null,
        }),
        ...(formMode === FORM_MODE_COMPANY &&
          legalName &&
          registrationNumber && {
            companyInfo,
          }),
      },
    }

    modifyReservation({
      reservationToken: reservation.token,
      modifications: [updateOwnerInfoModification],
    })

    showModal('')
  }
}

export { CustomerModalView }
