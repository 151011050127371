import React from 'react'
import PropTypes from 'prop-types'
import { UILayout, UIText } from 'bora-material-ui'
import messages from '../../../../src/consts/messages'

const PageNotFound = (props, { intl }) => (
  <UILayout j-flex-start>
    <UIText
      color="#4266bd"
      size="24px"
      margin="20px 0"
      weight="bold"
      text={intl.formatMessage(messages.staticPageNotFoundDescription)}
    />
  </UILayout>
)

PageNotFound.contextTypes = {
  intl: PropTypes.object,
}

export default PageNotFound
