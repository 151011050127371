import React, { PureComponent } from 'react'

import { Details } from '../../../components'

import messages from '../../../consts/messages'
import { VEHICLE_TYPE_TRAILER } from '../../../consts/reservation'

class VehicleDetailsView extends PureComponent {
  render() {
    const { vehicle } = this.props

    return (
      <Details>
        <Details.Item label={this.getLicencePlateLabel()}>{vehicle.licencePlate}</Details.Item>

        <Details.Item label={messages.carLength}>{this.renderLength()}</Details.Item>

        <Details.Item label={messages.carHeight}>{this.renderHeight()}</Details.Item>

        <Details.Item label={messages.carWidth}>{this.renderWidth()}</Details.Item>

        <Details.Item label={messages.carWeight}>{this.renderWeight()}</Details.Item>
      </Details>
    )
  }

  getLicencePlateLabel = () => {
    const { vehicle } = this.props

    if (vehicle.type === VEHICLE_TYPE_TRAILER) {
      return messages.trailerRegNr
    }

    return messages.carRegNr
  }

  renderLength = () => {
    const {
      vehicle: { lengthInCm },
    } = this.props

    if (typeof lengthInCm !== 'number') {
      return null
    }

    return `${lengthInCm / 100} m`
  }

  renderHeight = () => {
    const {
      vehicle: { heightInCm },
    } = this.props

    if (typeof heightInCm !== 'number') {
      return null
    }

    return `${heightInCm / 100} m`
  }

  renderWidth = () => {
    const {
      vehicle: { widthInCm },
    } = this.props

    if (typeof widthInCm !== 'number') {
      return null
    }

    return `${widthInCm / 100} m`
  }

  renderWeight = () => {
    const {
      vehicle: { weightInKg },
    } = this.props

    if (typeof weightInKg !== 'number') {
      return null
    }

    return `${weightInKg / 1000} t`
  }
}

export { VehicleDetailsView }
