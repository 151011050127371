import React from 'react'
import PropTypes from 'prop-types'
import { UIButton, UIIcon, UIImage, UILayout, UIText } from 'bora-material-ui'
import moment from 'moment'
import { head } from 'ramda'
import ReactHtmlParser from 'react-html-parser'
import './NewsStyles.css'

import { FRIENDLY_DATE_FORMAT } from '../../../consts/stuff'
import { olderBrowser } from '../../../utils/browserUtils'
import { THEME } from 'bora-material-ui/themes'
import { isMobile } from '../../Common/components/AppBar'
import s from './NewsItem.module.scss'
import { getStyle } from '../../../utils/liinilaevadThemeUtils'
import { getGavdosStyle } from '../../../utils/gavdosStyleUtils'

const titleStyle = {
  ...(olderBrowser && { marginLeft: '20px' }),
}

const cmsBase = window.brandProps.CMS_API_BASE
const expandIconType = window.brandProps.theme === THEME.LIINILAEVAD ? 'iconExpandLL' : 'iconChevron'
const fontFamily = window.brandProps.theme === THEME.LIINILAEVAD ? 'Mulish' : 'Roboto'

const getTitleTextColor = (muiTheme) => {
  return window.brandProps.theme === THEME.LIINILAEVAD ? muiTheme.palette.primary4Color : '#000000'
}

const getTitleFont = (muiTheme) => {
  return window.brandProps.theme === THEME.LIINILAEVAD ? muiTheme.secondFontFamily : 'Roboto'
}

class NewsItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = { showDescription: false }
  }

  toggleDescription = () => this.setState({ showDescription: !this.state.showDescription })

  render() {
    const { date, title, description = '', style, muiTheme, imagePath } = this.props

    const imageSrc = imagePath ? `${cmsBase}${imagePath}` : ''

    const iconStyles =
      window.brandProps.theme === THEME.LIINILAEVAD
        ? {
            width: '10px',
            height: '15px',
            fill: '#FFFFFF',
            transform: this.state.showDescription ? 'rotate(180deg)' : 'rotate(0deg)',
          }
        : {
            width: '10px',
            height: '15px',
            fill: '#FFFFFF',
            transform: this.state.showDescription ? 'rotate(270deg)' : 'rotate(90deg)',
          }

    // language=HTML
    return (
      <UILayout column padding="40px 0" xs={{ padding: '20px 0' }} style={style}>
        <UILayout j-flex-end padding="0 0 15px" xs={{ column: true, padding: '0 0 10px', position: 'relative' }}>
          <UILayout column style={{ maxWidth: '110px' }}>
            <UIText
              color={`${window.brandProps.theme === THEME.LIINILAEVAD ? '#a8a8a8' : '#8aa1ac'}`}
              size="18px"
              xs={{ size: '14px', align: 'start' }}
              text={moment(date).format(FRIENDLY_DATE_FORMAT)}
              font={fontFamily}
              weight="normal"
              lineHeight="1.5"
            />
          </UILayout>
          <UILayout column style={titleStyle} j-flex-start xs={{ style: { order: '4' } }}>
            <UIText
              color={getTitleTextColor(muiTheme)}
              size="18px"
              xs={{ size: '14px' }}
              text={title}
              font={getTitleFont(muiTheme)}
              weight="bold"
              lineHeight="1.5"
              align="left"
            />
          </UILayout>
          <UILayout
            column
            j-flex-start
            margin="0 0 0 55px"
            width="max-content"
            xs={{ position: 'absolute', top: '15px', right: '0' }}
          >
            {description && (
              <UIButton
                type="circle"
                width="30px"
                height="30px"
                background={getGavdosStyle('#4266bd')}
                xs={{ fill: '#0eb1bc', background: 'rgba(0,0,0,0)', align: 'right', boxShadow: 'unset' }}
                click={() => this.toggleDescription()}
              >
                <UIIcon type={expandIconType} margin="0 6px" style={iconStyles} />
              </UIButton>
            )}
          </UILayout>
        </UILayout>
        <div
          className={window.brandProps.theme === THEME.LIINILAEVAD && s.content}
          style={getStyle({ marginLeft: isMobile && 0 })}
        >
          {this.state.showDescription && imageSrc && <UIImage src={imageSrc} width="100%" />}
          {this.state.showDescription &&
            description &&
            head(ReactHtmlParser(`<div style="line-height: 1.33;">${description}</div>`))}
        </div>
      </UILayout>
    )
  }
}

NewsItem.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  style: PropTypes.object,
  muiTheme: PropTypes.object,
}

NewsItem.defaultProps = {
  style: {},
}

export default NewsItem
