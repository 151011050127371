import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UILayout } from 'bora-material-ui'
import ReactHtmlParser from 'react-html-parser'
import { compose, head, isEmpty, pathOr } from 'ramda'
import { NotFoundContent, PageTitle } from '../Common'
import { mapStateToPropsForStaticPages } from '../../../services/sales-static/utils'
import '../../../styles/sales-static.css'
import moduleStyles from './Content.module.scss'

const contentFontFamily = pathOr('GinesoNormDem, Fjalla One', ['brandProps', 'muiTheme', 'thirdFontFamily'])(window)
const cmsBase = window.brandProps.CMS_API_BASE

class RichTextContentPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      secondaryTitle: '',
      pageContent: null,
    }
  }

  componentDidMount() {
    this.fetchDataAndSetState()
  }

  fetchDataAndSetState() {
    const { params } = this.props.match
    // eslint-disable-next-line no-unused-vars
    const htmlWrapperStyle = `font-family: ${contentFontFamily}`
    this.props
      .fetch(this.props.locale, params.id || params.slug)
      .then((res) => {
        const secondaryTitle = compose(pathOr('', ['subject']), head)(res)
        const richTextContent = compose(pathOr('', ['body']), head)(res)

        let contentWithReplacedUnwantedStuff = richTextContent

        try {
          contentWithReplacedUnwantedStuff = richTextContent
            .replaceAll('src="/uploads/', `src="${cmsBase}/uploads/`)
            .replaceAll('<p>&nbsp;</p>', '')
        } catch (e) {
          console.warn('Dealing with some older browsers')
        }

        const divElement = document.createElement('div')
        divElement.innerHTML = contentWithReplacedUnwantedStuff

        for (const element of divElement.getElementsByTagName('*')) {
          element.removeAttribute('style')
        }

        const divId = `rich-${window.brandProps.theme}-content-${params.slug || ''}`

        const pageContent = richTextContent
          ? head(
              ReactHtmlParser(
                `<div id="${divId}"
                        class="${this.props.className} static-content-wrapper ${moduleStyles.around}"
                        style="${htmlWrapperStyle}">${divElement.innerHTML}
                </div>`
              )
            )
          : []
        this.setState({ pageContent, secondaryTitle })
      })
      .catch((error) => {
        console.log(error)
        this.setState({ pageContent: [] })
      })
  }

  render() {
    const { printable, pageTitle } = this.props
    const { pageContent, secondaryTitle } = this.state
    return (
      <UILayout>
        <NotFoundContent display-if={isEmpty(pageContent)} />
        <UILayout column display-if={!isEmpty(pageContent)} className={printable && 'printable'}>
          <PageTitle title={pageTitle} secondaryTitle={pageTitle ? '' : secondaryTitle} showPrintButton={printable} />
          <UILayout data-test="rich-content-layout" margin="20px 0 0 0" style={{ color: '#ffffff' }}>
            {pageContent || (
              <UILayout j-flex-center margin="60px">
                <CircularProgress />
              </UILayout>
            )}
          </UILayout>
        </UILayout>
      </UILayout>
    )
  }
}

RichTextContentPage.propTypes = {
  fetch: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  printable: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  pageTitle: PropTypes.string,
}

RichTextContentPage.defaultProps = {
  pageTitle: '',
  printable: false,
}

export default connect(mapStateToPropsForStaticPages)(RichTextContentPage)
