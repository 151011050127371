import React from 'react'
import PropTypes from 'prop-types'
import { HashRouter as Router, Switch } from 'react-router-dom'
import styled from 'styled-components'

import { UIBlock, UILayout } from 'bora-material-ui'
import UIElements from '../../consts/UIElements'
import NewsContent from './NewsContent'
import ShipsListContent from './ShipsListContent'
import ShipInfoContent from './ShipInfoContent'
import { RichTextContentPage } from './Common'
import { getAboutPage, getContacts, getPage, getSchedule } from '../../services/sales-static/api'
import { generateRoutesWithLocale } from '../../utils/locale'
import { THEME } from 'bora-material-ui/themes'

const renderContactsContent = (props) => (
  <RichTextContentPage {...props} key={props.match.url} fetch={getContacts} className="contacts" />
)

const renderAboutContent = (props) => (
  <RichTextContentPage {...props} key={props.match.url} fetch={getAboutPage} className="about-page" />
)

const renderScheduleContent = (props) => (
  <RichTextContentPage {...props} key={props.match.url} printable fetch={getSchedule} className="schedules" />
)

const renderPageContent = (props) => (
  <RichTextContentPage {...props} key={props.match.url} fetch={getPage} className="schedules" />
)

const BlockWithPrintStyles = styled(UIBlock)`
  @media print {
    padding: 0;
    margin: 0 auto;
  }
`

const LayoutWithPrintStyles = styled(UILayout)`
  @media print {
    padding: 0;
    margin: 0 auto;
    border: 3px solid orange;
  }
`

const routes = [
  { path: '/content/news', component: NewsContent },
  { path: '/content/contacts', render: renderContactsContent },
  { path: '/content/about', render: renderAboutContent },
  { path: '/content/fleet', component: ShipsListContent },
  { path: '/content/fleet/:slug', component: ShipInfoContent },
  { path: '/content/schedules/:id', render: renderScheduleContent },
  { path: '/content/:slug', render: renderPageContent },
]

const getPadding = () => {
  return window.brandProps.theme === THEME.LIINILAEVAD ? '2rem' : '40px 120px 60px 100px'
}

// eslint-disable-next-line react/prefer-stateless-function
class SalesStaticInsideView extends React.PureComponent {
  static contextTypes = {
    muiTheme: PropTypes.object,
  }

  render() {
    const { muiTheme } = this.context

    return (
      <LayoutWithPrintStyles
        className="sales-static-inside-layout"
        column
        j-flex-start
        padding={UIElements.CONTENT_PADDING}
        margin={`${UIElements.HEADER ? '-97px' : '40px'} 0 0`}
        maxWidth={UIElements.CONTENT_WIDTH}
        width="100%"
        xs={{ margin: '0', width: '100%' }}
        {...muiTheme.ids.mainInsideContent}
      >
        <BlockWithPrintStyles
          width="100%"
          margin="0 auto 0 0"
          bgColor="#ffffff"
          padding={getPadding()}
          color="#4266bd"
          boxSizing="border-box"
          data-test="sales-static-inside"
          ml={{ padding: '40px 16px 60px' }}
          xs={{ padding: '36px 16px' }}
          xsm={{ padding: '15px 10px' }}
        >
          <Router>
            <Switch>{generateRoutesWithLocale(routes)}</Switch>
          </Router>
        </BlockWithPrintStyles>
      </LayoutWithPrintStyles>
    )
  }
}

export default SalesStaticInsideView
