import React from 'react'
import PropTypes from 'prop-types'
import { UILayout, UIText, UIIcon } from 'bora-material-ui'
import messages from '../../../consts/messages'
import { THEME } from 'bora-material-ui/themes'

const getTextColor = (muiTheme) => {
  return window.brandProps.theme === THEME.LIINILAEVAD ? muiTheme.palette.darkPrimaryColor : '#4266bd'
}

const getStyles = () => {
  return window.brandProps.theme === THEME.LIINILAEVAD
    ? {
        borderBottom: '1px solid #E6E6E6;',
        fontSize: '24px',
        justifyContent: 'center',
      }
    : { borderBottom: '1px solid #4266bd', fontSize: '36px' }
}

const PageTitle = (props, context) => {
  const { title, secondaryTitle, showPrintButton } = props
  const { intl, muiTheme } = context
  return (
    <UILayout
      className="printable-layout-title"
      position="relative"
      padding="0 0 40px 0"
      style={getStyles()}
      xs={{ padding: '0 0 36px 0', style: { fontSize: '24px', borderBottom: '1px solid #4266bd' } }}
    >
      <UIText
        color={getTextColor(muiTheme)}
        font={muiTheme.secondFontFamily}
        size="inherit"
        text={title}
        weight="800"
      />
      <UIIcon
        display-if={title && secondaryTitle}
        type="arrow"
        margin="auto 16px"
        width="16px"
        minWidth="16px"
        minHeight="16px"
        fill="#0eb1bc"
      />
      <UILayout
        display-if={showPrintButton}
        cursor="pointer"
        width="auto"
        onClick={showPrintButton && (() => window.print())}
        id="print-icon"
        position="absolute"
        right="0"
        top="15px"
        style={{ fontSize: '14px', textTransform: 'uppercase' }}
        xs={0}
      >
        <UIIcon
          type="iconShape"
          margin="0 6px"
          style={{
            width: '10px',
            height: '15px',
            fill: '#b0bec5',
          }}
        />
        <UIText size="inherit" weight="bold" color="#b0bec5" text={intl.formatMessage(messages.printButton)} />
      </UILayout>
      <UIText
        className="secondary-title"
        display-if={secondaryTitle}
        color={getTextColor(muiTheme)}
        size="inherit"
        text={secondaryTitle}
        font={muiTheme.secondFontFamily}
        weight="bold"
      />
    </UILayout>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string,
  secondaryTitle: PropTypes.string,
  showPrintButton: PropTypes.bool,
}

PageTitle.defaultProps = {
  title: '',
  secondaryTitle: '',
  showPrintButton: false,
}

PageTitle.contextTypes = {
  intl: PropTypes.any,
  muiTheme: PropTypes.any,
}

export default PageTitle
