import React from 'react'
import { UILayout, UIText } from 'bora-material-ui'
import PropTypes from 'prop-types'

const LiinilaevadFooter = () => (
  <UILayout
    data-testid="ll-footer"
    j-flex-center
    align="center"
    bgColor="#000085"
    zIndex="100"
    height="60px"
    width="100vw"
    style={{ marginTop: 'auto' }}
  >
    <a href="https://bora.systems/" target="_blank" style={{ textDecoration: 'none' }}>
      <UIText text="© Spinnaker OÜ | Powered by Bora Systems" color="white" weight="400" size="14px" />
    </a>
  </UILayout>
)

const CategoryHeader = (props) => {
  const { text } = props
  return (
    <UIText
      textTransform="uppercase"
      id="footer-bar-label"
      align="left"
      cursor="pointer"
      padding="0 0 7px 0"
      margin="0 0 16px 0"
      width="auto"
      text={text}
      size="24px"
      color="#FFF"
    />
  )
}

CategoryHeader.propTypes = {
  text: PropTypes.string.isRequired,
}

export default LiinilaevadFooter
