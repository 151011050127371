import React from 'react'
import { UILayout, UIText, UIIcon } from 'bora-material-ui'

const SeaJetsPromotions = () => (
  <UILayout column>
    <UIIcon type="wave" width="100%" height="700px" />
    <UIIcon type="whiteWave" width="100%" height="unset" />
    <UILayout
      bgColor="#FFF"
      j-flex-center
      zIndex="1"
    >
      <UILayout
        bgColor="#FFF"
        column
        width="960px"
      >
        <UIText
          text="Welcome to Seajets"
          align="center"
          color="#002F6C"
          weight="600"
          margin="0 0 50px 0"
          size="44px"
        />
        <UIText
          color="#002F6C"
          size="16px"
          weight="400"
          margin="0 0 60px 0"
          text="Next stop. Aegean blue, Your hyper-connected traveling partner for a fast ans safe journey to the Cyclades and Crete. With 140 connections, 26 ports and 11 ships, there is no need to look any further."
        />
      </UILayout>
    </UILayout>
  </UILayout>)

export default SeaJetsPromotions
