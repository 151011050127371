import React from 'react'
import { UILayout, UIImage } from 'bora-material-ui'
import messages from '../../../../../src/consts/messages'
import PropTypes from 'prop-types'

const GavdosMapPortName = ({ portName, style }) => (
  <div
    style={
      Object.assign(style, {
        position: 'absolute',
        color: '#ffff',
        fontSize: '1.0vw',
        fontFamily: 'AdelleSansBold',
        lineHeight: '28px'
      })}
  >{portName}</div>
)

const GavdosMapView = (_, { intl }) => (
  <UILayout
    style={{
      position: 'absolute'
    }}>
    <UIImage src="images/gavdos/map.png" width="100%" height="100%"/>
    <GavdosMapPortName
      portName={intl.formatMessage(messages.portNamePlakias)}
      style={{ top: '47%', left: '47%' }}/>
    <GavdosMapPortName
      portName={intl.formatMessage(messages.portNameAgiaGalini)}
      style={{ top: '55%', left: '62%' }}/>
    <GavdosMapPortName
      portName={intl.formatMessage(messages.portNameGavdos)}
      style={{ bottom: '2%', left: '35%' }}/>
  </UILayout>
)

GavdosMapView.contextTypes = {
  intl: PropTypes.object
}

export default GavdosMapView
