import React from 'react'
import { UILayout, UIText } from 'bora-material-ui'
import { map, keys } from 'ramda'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const footerContent = {
  'Company information': {
    'AS TALLINK GRUPP →': 'http://www.tallink.com/about',
    'Image Bank →': 'http://www.tallink.com/image-bank',
    'Contact Details →': 'https://www.tallinksilja.com/en/web/int/contact-us',
    'Terminal Information →': 'https://www.tallinksilja.com/en/web/int/terminals',
    'Check-in →': '/',
    'Latest News →': 'https://www.tallinksilja.com/en/web/int/latest-news'
  },
  'Useful Information': {
    'Good to know →': 'https://www.tallinksilja.com/en/web/int/good-to-know',
    'Timetables →': 'https://www.tallinksilja.com/en/web/int/routes-and-timetables',
    'Press releases →': 'http://www.tallink.com/press-releases',
    'Book online →': 'https://booking.tallink.com/?voyageType=ROUTETRIP&amp;from=hel&amp;to=sto&amp;locale=en&amp;country=XZ',
    'Privacy Policy →': 'https://www.tallinksilja.com/en/web/int/privacy-policy',
    'Read Brochure →': '/documents/10192/17625693/2015_TallinkSilja_Brochure_ENG_low.pdf/e0e512f0-7fc2-47ab-b955-13cd04346607',
    'Disabled and handicapped passengers →': 'https://www.tallinksilja.com/en/web/int/assistance-services'
  },
  'OTHER PAGES': {
    'AS TALLINK GRUPP →': 'http://www.tallink.com/about',
    'Image Bank →': 'http://www.tallink.com/image-bank',
    'Contact Details →': 'https://www.tallinksilja.com/en/web/int/contact-us',
    'Terminal Information →': 'https://www.tallinksilja.com/en/web/int/terminals',
    'Check-in →': '/',
    'Latest News →': 'https://www.tallinksilja.com/en/web/int/latest-news'
  },
  'FOLLOW US': {
    'Good to know →': 'https://www.tallinksilja.com/en/web/int/good-to-know',
    'Timetables →': 'https://www.tallinksilja.com/en/web/int/routes-and-timetables',
    'Press releases →': 'http://www.tallink.com/press-releases',
    'Book online →': 'https://booking.tallink.com/?voyageType=ROUTETRIP&amp;from=hel&amp;to=sto&amp;locale=en&amp;country=XZ',
    'Privacy Policy →': 'https://www.tallinksilja.com/en/web/int/privacy-policy',
    'Read Brochure →': '/documents/10192/17625693/2015_TallinkSilja_Brochure_ENG_low.pdf/e0e512f0-7fc2-47ab-b955-13cd04346607',
    'Disabled and handicapped passengers →': 'https://www.tallinksilja.com/en/web/int/assistance-services'
  }
}

const TallinkFooter = () => (
  <UILayout column>
    <UILayout
      j-flex-center
      bgColor="#004152"
    >
      <UILayout
        width="960px"
        margin="0 0 55px 0"
      >
        <UILayout xs={{ column: true, margin: '0 0 0 20px' }}>
          {
            map(
              (cat) => {
                const links = map(
                  (label) => <FooterItem key={label} text={label} link={footerContent[cat][label]} />,
                  keys(footerContent[cat])
                )
                return (
                  <UILayout key={cat} column margin="4px 24px 0 0" width="222px" padding="30px 0 0 0">
                    <CategoryHeader text={cat} />
                    {links}
                  </UILayout>
                )
              },
              keys(footerContent)
            )
          }
        </UILayout>
      </UILayout>
    </UILayout>
    <UILayout
      j-flex-center
      bgColor="#ddd"
      zIndex="100"
    >
      <UILayout
        width="960px"
      >
        <UILayout j-flex-end>
          <UIText text="© AS Tallink Grupp 2018" color="#004152" weight="400"/>
        </UILayout>
      </UILayout>
    </UILayout>
  </UILayout>
)

const FooterItem = (props) => {
  const { text, link } = props
  return (
    <Link to={link} key={text} style={{ textDecoration: 'none', padding: '0 0 7px 0' }}>
      <UIText
        textTransform="uppercase"
        id="footer-bar-item"
        cursor="pointer"
        align="left"
        width="auto"
        text={text}
        size="12px"
        color="#FFF"
        weight="inherit"
      />
    </Link>
  )
}

const CategoryHeader = (props) => {
  const { text } = props
  return (
    <UIText
      textTransform="uppercase"
      id="footer-bar-label"
      align="left"
      cursor="pointer"
      padding="0 0 7px 0"
      margin="0 0 16px 0"
      width="auto"
      text={text}
      size="24px"
      color="#FFF"
    />
  )
}

FooterItem.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
}

CategoryHeader.propTypes = {
  text: PropTypes.string.isRequired
}

export default TallinkFooter
