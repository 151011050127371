import React, { PureComponent } from 'react'
import { EditTicketContainer } from './EditTicketContainer'

class StandaloneEditTicket extends PureComponent {
  render() {
    return (
      <div
        style={{
          backgroundColor: '#ffffff',
          boxSizing: 'border-box',
          marginTop: '40px',
          padding: '28px 40px',
          width: '100%',
        }}
      >
        <EditTicketContainer {...this.props} />
      </div>
    )
  }
}

export { StandaloneEditTicket }
