import React from 'react'
import { UILayout, UIText } from 'bora-material-ui'


const PosidoniaFooter = () => (
  <UILayout
    data-test="copyright"
    margin="50px auto 20px auto"
    opacity="0.8"
    maxWidth="960px"
  >
    <UIText
      align="left"
      color="rgb(44, 70, 132)"
      margin="0 0 0 20px"
    >
      &copy; Bora
    </UIText>
  </UILayout>
)

export default PosidoniaFooter
