import React from 'react'
import { UILayout, UIText, UIIcon } from 'bora-material-ui'
import { map, keys } from 'ramda'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const footerContent = {

  first: {
    HOME: 'https://www.seajets.gr/en/Home-214.htm',
    'TERA JET 2017': 'https://www.seajets.gr/en/TERA-JET-2017-944.htm',
    'The Company': 'https://www.seajets.gr/en/The-Company-520.htm',
    'General Terms': 'https://www.seajets.gr/en/General-Terms-767.htm',
    Contact: 'https://www.seajets.gr/en/Contact-us-448.htm',
    'Destinations & Routes': 'https://www.seajets.gr/en/destinations-and-routes-960.htm'
  },
  Mirrows: {
    'United Kingdom / Seajets.uk': '',
    'Greece / Seajets.gr': '',
    'Russia / Seajets.ru': '',
    'Germany / Seajets.de': '',
    'France / Seajets.fr': '',
    'Italy / Seajets.it': '',
    'Spain / Seajets.es': '',
    'China / Seajets.zh': ''
  }
}

const SeaJetsFooter = () => (

  <UILayout column>
    {/* temporary fix */}
    <div style={{ marginBottom: '-8px' }}>
      <UIIcon type="blueWave" width="100%" height="unset"/>
    </div>
    <UILayout
      j-flex-center
      bgColor="#002F6C"
      zIndex="1"
    >
      <UILayout
        width="960px"
        margin="0 0 55px 0"
        column
      >
        <UIIcon width="127px" height="53px" type="logo_seajets" fill="#335989" />
        <UILayout xs={{ column: true, margin: '0 0 0 10px' }}>
          {
            map(
              (cat) => {
                const links = map(
                  (label) => <FooterItem key={label} text={label} link={footerContent[cat][label]} />,
                  keys(footerContent[cat])
                )
                return (
                  <UILayout key={cat} column margin="4px 24px 0 0" width="222px" padding="30px 0 0 0">
                    {links}
                  </UILayout>
                )
              },
              keys(footerContent)
            )
          }
          <UILayout column margin="4px 24px 0 0" width="222px" padding="30px 0 0 0" align="start">
            <UIText size="24px" text="Contact details" margin="0 0 20px 0" color="#7D93B3" />
            <UIText
              text="Astiggos &amp; Akti Tzelepi 2 185 31 Piraeus"
              color="#7D93B3"
              align="start"
            />
            <LinkItem link="tel:00302104121001" text="Call Center: +30 210 4121001"/>
            <LinkItem link="tel:00302104121901" text="+30 210 4121901"/>
            <LinkItem link="fax:00302104132559" text="Fax: +30 210 4132559"/>
            <LinkItem link="fax:00302104121912" text="+30 210 4121912"/>
            <LinkItem link="mailto:info@seajets.gr" text="E-mail: info@seajets.gr"/>
          </UILayout>
        </UILayout>
      </UILayout>
    </UILayout>
    <UILayout
      j-flex-center
      bgColor="#ddd"
      zIndex="100"
    >
      <UILayout
        width="960px"
      >
        <UILayout j-flex-end>
          <UIText text="Copyright © 2018 Seajets.gr - All Rights Reserved" color="#004152" weight="400" />
        </UILayout>
      </UILayout>
    </UILayout>
  </UILayout>
)

const FooterItem = (props) => {
  const { text, link } = props
  return (
    <Link to={link} key={text} style={{ textDecoration: 'none', padding: '0 0 7px 0' }}>
      <UIText
        textTransform="uppercase"
        id="footer-bar-item"
        cursor="pointer"
        align="left"
        width="auto"
        text={text}
        size="12px"
        color="#FFF"
        weight="inherit"
      />
    </Link>
  )
}

const CategoryHeader = (props) => {
  const { text } = props
  return (
    <UIText
      textTransform="uppercase"
      id="footer-bar-label"
      align="left"
      cursor="pointer"
      padding="0 0 7px 0"
      margin="0 0 16px 0"
      width="auto"
      text={text}
      size="24px"
      color="#FFF"
    />
  )
}

const LinkItem = (props) => {
  const { text, link } = props
  return (
    <a href={link} style={{ textDecoration: 'none' }}>
      <UIText
        text={text}
        color="#7D93B3"
        align="start"
        margin="10px 0"
      />
    </a>
  )
}

LinkItem.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
}

FooterItem.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
}

CategoryHeader.propTypes = {
  text: PropTypes.string.isRequired
}

export default SeaJetsFooter
